<template>
  <div>
    <message-dialog
        v-model="errorDialog"
        v-on:OK="errorDialog = false"
        title="Login failed"
    >
      <p>
        Oops! Login failed! Please try again!
      </p>
      <p>
        Or visit our <a href="/signup">sign up page.</a>
      </p>
    </message-dialog>
    <v-card>
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-row>
          <v-text-field
              v-model="username"
              label="Username"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-text-field
              v-model="password"
              label="Password"
              type="password"
          ></v-text-field>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-on:click="login(username, password)">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import MessageDialog from "@/components/MessageDialog";
import {Action} from '@/store/session/types'

export default {
  name: "LoginUi",
  components: {MessageDialog},
  data: () => ({
    username: "",
    password: "",
    errorDialog: false,
  }),
  methods: {
    login: function(username, password) {
      // TODO: remember username somewhere in vuex store...?
      // - or local storage...?
      this.$store.dispatch(
          `${[Action.Login]}`,
          {username: username, password: password}
      ).then(
          () => {
            this.password = ""
            this.$emit('success')
          }
      ).catch(
          () => {
            this.errorDialog = true
            this.$emit('fail')
          }
      )
    }
  },
}
</script>

<style scoped>

</style>
