<template>
  <v-container>
    <v-select
        :value="selectedFinpicId"
        :items="finpicList"
        item-text="name"
        item-value="finpicid"
        label="Active Finpic"
        v-on:change="updateFinpic"
      ></v-select>
    <v-btn @click="runTest">Test</v-btn>
    <v-dialog v-model="dialog">
      <login-ui
        v-on:success="success"
        />
    </v-dialog>
  </v-container>
</template>

<script>
import LoginUi from "@/components/LoginUi";
import {Getter, Action} from '@/store/profile/types'
import {Action as SummaryAction} from '@/store/summary/types'
export default {
  name: "Profile",
  components: {LoginUi},
  metaInfo: {
    title: 'Profile',
  },
  data: () => ({
    selectedFinpicId: 0,
    dialog: false,
  }),
  computed: {
    activeFinpic() {
      let item = this.$store.getters[`${[Getter.getActiveFinpic]}`]
      console.log(item)
      return item
    },
    finpicList() {
      return this.$store.getters[`${[Getter.getFinpicList]}`]
    },
  },
  mounted() {
    this.$store.dispatch(`${[Action.loadUserProfile]}`)
    this.$store.dispatch(`${[Action.listFinpic]}`)

    this.selectedFinpicId = this.$store.getters[`${[Getter.getActiveFinpic]}`].finpicid
  },
  watch: {
    activeFinpic(val) {
      this.selectedFinpicId = val.finpicid
    },
  },
  methods: {
    updateFinpic: function(val) {
      this.$store.dispatch(`${[Action.setActiveFinpic]}`, val)
    },
    runTest: function() {
      console.log("running")
      this.$store.dispatch(`${[SummaryAction.FetchSummary]}`)
    },
    success: function() {
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>
