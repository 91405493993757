<template>
    <v-dialog v-model="value"
              v-on:click:outside="$emit('OK')"
              max-width="500px"
    >
      <v-card >
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text>
          <slot name="default">
          </slot>
          <v-card-actions>
            <v-spacer />
            <v-btn
                v-on:click="$emit('OK')"
            >OK</v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: "NothingDialog",
  props: {
    value: null,
    title: null,
  }
}
</script>

<style scoped>
</style>
